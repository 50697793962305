import ExtLink from 'components/common/ExtLink'
import IntLink from 'components/common/IntLink'
import TelegramIcon from 'components/icons/TelegramIcon'
import VkIcon from 'components/icons/VkIcon'
import WhatsAppIcon2 from 'components/icons/WhatsAppIcon2'
import {
  ADDRESS,
  CONTACT_EMAIL,
  CONTACT_PHONE,
  FOOTER_ABOUT_PAGE_LIST,
  TELEGRAM_LINK,
  VK_LINK,
} from 'config/constants'
import { getServices } from 'lib/getServices'
import { isAbsent, isNonEmptyArray, isPresent } from 'lib/utils'
import Image from 'next/image'
import appStore from 'public/img/App_Store.png'
import googlePlay from 'public/img/Google_Play.png'
import img1 from 'public/img/footer1.png'
import img2 from 'public/img/footer2.png'
import img3 from 'public/img/footer3.png'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCities, type IService } from 'store/api'

const FooterMbl = () => {
  const cities = useSelector(selectCities('ru'))

  const [services, setServices] = useState<IService[]>([])

  const defaultCity = useMemo(
    () => (isNonEmptyArray(cities) ? cities[0] : null),
    [cities]
  )

  useEffect(() => {
    void (async () => {
      const servicesData = await getServices('ru')

      if (isPresent(servicesData)) {
        setServices(servicesData.services)
      }
    })()
  }, [])

  // const openHours = useMemo(
  //   () =>
  //     `Ежедневно ${defaultCity?.call_time.begin || '08:00'} - ${
  //       defaultCity?.call_time.end || '20:00'
  //     }`,
  //   [defaultCity]
  // )

  if (isAbsent(defaultCity)) return null

  const cateringComponent = (
    <div className='flex flex-col mt-6'>
      <IntLink
        href={`/catering`}
        className='text-sm mb-2 !leading-6 font-bold text-dark uppercase'>
        Кейтеринг
      </IntLink>

      {services.map(({ name, slug }) => (
        <div key={slug}>
          <IntLink
            href={`/catering/${slug}`}
            className='line-clamp-1 text-base2 !leading-7 text-mediumDark'
          >
            {name}
          </IntLink>
        </div>
      ))}
    </div>
  )

  const aboutComponent = (
    <div className='flex flex-col mt-9'>
      <IntLink
        href={`/about`}
        className='text-sm mb-2 !leading-6 font-bold text-dark uppercase'>
        О нас
      </IntLink>

      {FOOTER_ABOUT_PAGE_LIST.map((x) => (
        <div key={x.href}>
          <IntLink
            shallow={!x.deep}
            href={x.href}
            className='text-base2 !leading-6 text-mediumDark'
          >
            {x.name}
          </IntLink>
        </div>
      ))}
    </div>
  )

  return (
    <footer className='bg-lightGrey custom-px pt-9 md:pt-4 pb-10 lg:bg-spoons-footer-pattern'>
      {cateringComponent}

      {aboutComponent}

      <div className='grid grid-cols-2 justify-between gap-4 md:flex pb-4 lg:pb-2 mt-8 border-t pt-9'>
        <div>
          <div className='font-bold text-sm text-dark !leading-7'>
            Режим работы:
          </div>

          <div className='flex flex-row gap-2'>
            <div className='text-gray-500 text-xs md:text-base2 !leading-5'>
              Пн-сб
              <br />
              Вс
            </div>
            <div className='text-mediumDark text-xs md:text-base2 !leading-5'>
              8:00–20:00
              <br />
              9:00–20:00
            </div>
          </div>
        </div>
        <div className='lg:mt-8'>
          <div className='font-bold text-sm text-dark !leading-6'>
            Телефоны:
          </div>
          <a
            className='text-mediumDark text-xs md:text-base2 !leading-6'
            href={`tel:+${
              defaultCity?.phones[0]?.replace(/\D/g, '') ||
              CONTACT_PHONE.replace(/\D/g, '')
            }`}
          >
            {defaultCity?.phones[0] || CONTACT_PHONE}
          </a>
        </div>
        <div className='flex flex-col row-start-1 col-span-2 md:col-span-1 lg:mt-8'>
          <div className='text-sm !leading-6 font-bold text-dark inline-flex items-center gap-2'>
            Адрес:
          </div>
          <div className='md:text-base2 !leading-7 text-mediumDark'>
            {defaultCity?.location?.address || ADDRESS}
          </div>
        </div>
        <div className='row-start-3 col-start-1 lg:mt-8'>
          <div className='font-bold text-sm text-dark !leading-6'>
            E-mail:{' '}
          </div>
          <a
            className='text-mediumDark text-xs md:text-base2 !leading-5 underline'
            href={`mailto:${CONTACT_EMAIL}`}
          >
            {CONTACT_EMAIL}
          </a>
        </div>

        <div className='flex flex-row justify-center items-center row-start-4 col-span-2 md:col-span-1 mt-4'>
          <Image
            src={img1}
            layout='fixed'
            objectFit='contain'
            width='56px'
            height='23px'
          />
          <Image
            src={img2}
            layout='fixed'
            objectFit='cover'
            width='54px'
            height='36px'
          />
          <Image
            src={img3}
            layout='fixed'
            objectFit='contain'
            width='65px'
            height='23px'
          />
        </div>

        <div className='flex flex-row text-xs md:text-sm !leading-6 row-start-5 col-start-1 mt-6'>
          <iframe
            src='https://yandex.ru/sprav/widget/rating-badge/1286099425?type=rating'
            width='150'
            height='50'
            frameBorder='0'
          ></iframe>
        </div>

        <div className='flex flex-row text-xs md:text-sm !leading-6 row-start-5 col-start-2 mt-6'>
          <iframe
            src='/misc/gis-mbl.html'
            frameBorder='0'
            width='165'
            height='50'
            sandbox='allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation-by-user-activation'
          ></iframe>
        </div>
      </div>

      <div className='flex flex-col-reverse md:flex-col mt-2'>
        <div className='flex justify-between flex-col md:flex-row items-center pt-8 border-t'>
          <div className='flex gap-3 items-center'>
            <ExtLink title='ВКонтакте' href={VK_LINK}>
              <VkIcon className='w-8 h-8 rounded hover:text-darkYellow' />
            </ExtLink>
            <ExtLink title='Телеграм' href={TELEGRAM_LINK}>
              <TelegramIcon className='w-8 h-8 rounded hover:text-darkYellow' />
            </ExtLink>
            <ExtLink
              title='WhatsApp'
              href={`https://wa.me/+79804002225`}
              className='flex flex-col items-center gap-2.5'
            >
              <WhatsAppIcon2 className='w-8 h-8 rounded hover:text-darkYellow' />
            </ExtLink>
          </div>

          <div className='flex flex-col md:flex-row gap-1 md:gap-4 items-center md:pt-2 mt-4'>
            <span className='pt-2 md:pt-0 block text-dark/70 text-xs md:text-base2'>
              Скачивайте приложение:
            </span>
            <div className='flex flex-row gap-1 md:gap-3'>
              <ExtLink href='https://play.google.com/store/apps/details?id=com.moscowfood'>
                <Image
                  src={googlePlay}
                  layout='fixed'
                  objectFit='contain'
                  width='120px'
                  height='40px'
                  title='Google Play'
                  alt='Google Play'
                />
              </ExtLink>
              <ExtLink href='https://apps.apple.com/ru/app/moscowfood/id6447418248'>
                <Image
                  src={appStore}
                  layout='fixed'
                  objectFit='contain'
                  width='120px'
                  height='40px'
                  title='App Store'
                  alt='App Store'
                />
              </ExtLink>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default FooterMbl
